export default {
  includeIds: {
    name: "id",
    type: "whereIn",
    value: []
  },
  excludeIds: {
    name: "id",
    type: "whereNotIn",
    value: []
  },
  allIds: {
    name: "id",
    type: "all",
    value: ""
  }
};
