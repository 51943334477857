<template>
  <b-card>
    <router-link v-if="canCreate" :to="{ name: `CounterpartActionsList` }">
      <b-button variant="success" size="sm" class="mb-2">
        <i class="fa fa-dot-circle-o"></i> Створити завдання
      </b-button>
    </router-link>
    <b-row>
      <b-col>
        <b-alert show variant="primary">
          <b-row>
            <b-col xl="6">
              <p>
                <b-badge pill variant="dark">Чекає виконання</b-badge> -
                перевірка ще не розпочалась.
              </p>
              <p>
                <b-badge pill variant="secondary">В обробці</b-badge> -
                виконується перевірка.
              </p>
              <p>
                <b-badge pill variant="warning">Завершено</b-badge> - перевірку
                завершено, потребує додаткових дій.
              </p>
            </b-col>
            <b-col xl="6">
              <p>
                <b-badge pill variant="success">Виконано</b-badge> - перевірку
                виконано.
              </p>
              <p>
                <b-badge pill variant="danger">Помилка</b-badge> - виникла
                невідома помилка, повідомте службу підтримки.
              </p>
            </b-col>
          </b-row>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="tasks.length > 0">
      <b-col cols="12" class="mr-auto p-3">
        <b-card
          v-for="task in tasks"
          :key="task.id"
          no-body
          class="d-flex justify-content-between align-items-center flex-row"
        >
          <div class="p-3 col col-lg-2 col-xl-3 d-flex">
            {{ task.type.name }}
          </div>
          <div class="p-3 col d-flex justify-content-center">
            <b-badge :variant="getColorStatus(task.status.value)" pill>
              {{ task.status.name }}
            </b-badge>
          </div>
          <div class="p-3 col d-flex justify-content-center">
            {{ task.causer.email }}
          </div>
          <div class="p-3 col d-flex justify-content-center">
            {{ task.started | setStartTimeMoment }}
          </div>
          <div class="p-3 col d-flex justify-content-end">
            <b-button
              variant="primary"
              :disabled="
                task.status.value === 'waiting-to-start' ||
                (task.type.value !== 'public-persons' &&
                  task.type.value !== 'sanctions')
              "
              @click="followToCurrentItem(task)"
            >
              <b-icon icon="eye-fill"></b-icon>
              Перегянути
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="tasks.length > 0">
      <b-col cols="auto" class="mr-auto p-3">
        <nav>
          <b-pagination
            :total-rows="totalItemsCount"
            :per-page="count"
            v-model="page"
            prev-text="Попередня"
            next-text="Наступна"
            hide-goto-end-buttons
          />
        </nav>
      </b-col>

      <b-col cols="auto" class="p-3">
        <b-form-radio-group
          buttons
          v-model="count"
          :options="options"
          button-variant="outline-primary"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col> Немає завдань </b-col>
    </b-row>
    <b-modal
      id="task-counterparts-modal"
      ref="task-counterparts-modal"
      no-close-on-backdrop
      hide-footer
      size="xl"
      style="max-width: 90%"
    >
      <public-persons-form
        v-if="
          taskCounterparts.length > 0 &&
          task &&
          task.type.value === 'public-persons'
        "
        :task="task"
      />
      <sanctions-form
        v-else-if="
          taskCounterparts.length > 0 && task && task.type.value === 'sanctions'
        "
        :task="task"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment/moment";
import { mapFields } from "vuex-map-fields";
import PublicPersonsForm from "@/entity/Tasks/forms/PublicPersonsForm";
import SanctionsForm from "@/entity/Tasks/forms/SanctionsForm";
import debounce from "debounce";
import { PERMISSION_CREATE_TASK, PERMISSION_VIEW_TASK } from "@/constants";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(PERMISSION_VIEW_TASK)) next();
      else next({ name: "Page404" });
    });
  },
  name: "tasks-list",
  components: { PublicPersonsForm, SanctionsForm },
  created() {
    this.$store.dispatch("tasks/setTasks");
    this.debouncedPaginator = debounce(this.paginator, 500);
  },
  watch: {
    page: function () {
      this.debouncedPaginator();
    },
    count: function () {
      this.$store.dispatch("tasks/setTasks");
    },
  },
  computed: {
    ...mapGetters({
      tasks: "tasks/getAllTasks",
      totalItemsCount: "tasks/totalTaskItems",
      taskCounterparts: "tasks/getAllTaskCounterparts",
    }),
    ...mapFields("tasks", {
      count: "countTasks",
      page: "pageTasks",
    }),
    canCreate: function () {
      return this.$auth.can(PERMISSION_CREATE_TASK);
    },
  },
  data() {
    return {
      task: null,
      options: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "250", value: 250 },
      ],
    };
  },
  methods: {
    paginator: function () {
      this.$store.dispatch("tasks/setTasks");
    },
    getColorStatus(value) {
      if (value === "done") return "success";
      else if (value === "success") return "warning";
      else if (value === "pending") return "secondary";
      else if (value === "error") return "danger";
      else if (value === "waiting-to-start") return "dark";
    },
    followToCurrentItem(task) {
      this.task = task;
      this.$store
        .dispatch("tasks/setTaskCounterparts", task.id)
        .then(() => this.$refs["task-counterparts-modal"].show());
    },
  },
  filters: {
    setStartTimeMoment: function (date) {
      return date
        ? moment(date).format("DD.MM.YYYY, HH:mm:ss")
        : "не почалась перевірка";
    },
  },
};
</script>

<style lang="scss">
#task-counterparts-modal {
  .modal-dialog {
    max-width: 90% !important;
  }
  .tooltip {
    max-width: none;
    white-space: pre-wrap;
    width: auto;

    .tooltip-inner {
      max-width: none;
      width: auto;
    }
  }
}
</style>
