<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-table
      id="task-sanction-table"
      :dark="false"
      :fields="showHeaders"
      :hover="true"
      :striped="true"
      :bordered="true"
      :small="false"
      :fixed="true"
      responsive="sm"
      show-empty
      :items="applyShortFilter(dataList)"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'selected' ? '42px' : 'auto',
          }"
        />
      </template>

      <template #cell(selected)="data">
        <b-form-checkbox
          v-if="
            task.status.value === 'success' &&
            data.item.statusValue === 'success' &&
            data.item.deleted === null &&
            (data.item.currentSanctions !== data.item.newSanctions ||
              data.item.currenSanctionsDescription !==
                data.item.newSanctionsDescription)
          "
          :value="data.item.id"
          v-model="selectedItems"
          @change="checkItems($event, data.item)"
        ></b-form-checkbox>
      </template>

      <template #head(selected)="data">
        <b-form-checkbox
          v-if="task.status.value === 'success'"
          v-model="allItemsSelected"
          :indeterminate="indeterminate"
          @change="selectedAllItems()"
        ></b-form-checkbox>
      </template>

      <template v-slot:cell(name)="data">
        <router-link
          :to="{ path: `${itemEndpoint}/${data.item.idCounterpart}` }"
          target="_blank"
        >
          {{ data.item.name }}
        </router-link>
      </template>

      <template v-slot:cell(started)="data">
        {{ data.item.started | setStartTimeMoment }}
      </template>

      <template v-slot:cell(currentSanctions)="data">
        {{ data.item.currentSanctions | boolToText }}
      </template>

      <template v-slot:cell(newSanctions)="data">
        {{ data.item.newSanctions | boolToText }}
      </template>

      <template v-slot:cell(currenSanctionsDescription)="data">
        <div v-b-tooltip="data.item.currenSanctionsDescription">
          {{ data.item.currenSanctionsDescription }}
        </div>
      </template>

      <template v-slot:cell(newSanctionsDescription)="data">
        <div v-b-tooltip="data.item.newSanctionsDescription">
          {{ data.item.newSanctionsDescription }}
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <nav>
          <b-pagination
            :total-rows="totalTaskCounterpartsItemsCount"
            :per-page="count"
            v-model="page"
            prev-text="Попередня"
            next-text="Наступна"
            hide-goto-end-buttons
          />
        </nav>
      </b-col>
    </b-row>

    <b-button
      type="submit"
      size="sm"
      variant="success"
      v-if="task.status.value === 'success' && me.id === task.causer.id"
    >
      <i class="fa fa-dot-circle-o"></i> Застосувати
    </b-button>
  </b-form>
</template>

<script>
import moment from "moment/moment";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "debounce";
import filterList from "@/entity/Tasks/structure/filter";

export default {
  props: ["task"],
  name: "sanctions-form",
  components: {},
  data() {
    return {
      filter: filterList,
      itemEndpoint: "/entity/counterpart",
      fields: [
        { key: "selected", label: "Дії" },
        { key: "name", label: "Контрагент" },
        { key: "status", label: "Статус" },
        {
          key: "currentSanctions",
          label: "Поточний статус у санкційних списках",
        },
        { key: "newSanctions", label: "Виявлений статус у санкційних списках" },
        {
          key: "currenSanctionsDescription",
          label: "Опис санкцій поточний",
        },
        { key: "newSanctionsDescription", label: "Опис санкцій виявлений" },
        { key: "started", label: "Дата старту" },
      ],
      indeterminate: false,
      allItemsSelected: false,
      selectedItems: [],
    };
  },
  computed: {
    ...mapGetters({
      dataList: "tasks/getAllTaskCounterparts",
      totalTaskCounterpartsItemsCount: "tasks/totalTaskCounterpartItems",
      me: "auth/me",
    }),
    ...mapFields("tasks", {
      count: "countTaskCounterparts",
      page: "pageTaskCounterparts",
    }),
    showHeaders() {
      return this.task.status.value === "success" ||
        this.task.status.value === "done"
        ? this.fields
        : this.fields.filter(
            (s) =>
              s.key !== "currentSanctions" &&
              s.key !== "newSanctions" &&
              s.key !== "currenSanctionsDescription" &&
              s.key !== "newSanctionsDescription"
          );
    },
  },
  beforeDestroy() {
    Object.keys(this.filter).forEach((key) => {
      this.filter[key].value = "";
    });
  },
  created() {
    this.debouncedPaginator = debounce(this.paginator, 500);
  },
  watch: {
    page: function () {
      this.debouncedPaginator();
    },
  },
  methods: {
    onSubmit() {
      this.$store.commit("tasks/setTaskCounterpartsFilter", this.filter);
      this.$store
        .dispatch("tasks/counterpartsUpdate", this.task.id)
        .then(() => this.$parent.$parent.hide());
    },
    paginator: function () {
      this.$store
        .dispatch("tasks/setTaskCounterparts", this.task.id)
        .then(() => {
          if (this.allItemsSelected) {
            this.dataList.forEach((value) => {
              if (!this.selectedItems.includes(value.id)) {
                this.selectedItems.push(value.id);
              }
            });
          }
        });
    },
    selectedAllItems: function () {
      if (this.allItemsSelected && !this.indeterminate) {
        this.dataList.forEach((value) => {
          if (!this.selectedItems.includes(value.id)) {
            this.selectedItems.push(value.id);
          }
        });
        this.filter.allIds.value = true;
      } else {
        this.selectedItems = [];
        this.allItemsSelected = false;
        this.indeterminate = false;
        this.filter.includeIds.value = [];
        this.filter.excludeIds.value = [];
        this.filter.allIds.value = "";
      }
    },
    checkItems(items, item) {
      if (
        items.length > 0 &&
        items.length !== this.totalTaskCounterpartsItemsCount &&
        !this.allItemsSelected
      ) {
        // точково обраних
        this.allItemsSelected = false;
        this.indeterminate = true;
        this.filter.includeIds.value = Object.assign(items);
        this.filter.excludeIds.value = [];
        this.filter.allIds.value = "";
      } else if (items.length === this.totalTaskCounterpartsItemsCount) {
        // обрані всі
        this.allItemsSelected = true;
        this.indeterminate = false;
        this.filter.includeIds.value = [];
        this.filter.excludeIds.value = [];
        this.filter.allIds.value = true;
      } else if (
        (this.allItemsSelected && !this.indeterminate) ||
        (this.allItemsSelected && this.indeterminate && items.length > 0)
      ) {
        // точково видалені
        this.allItemsSelected = true;
        this.indeterminate = true;
        this.filter.includeIds.value = [];
        this.filter.allIds.value = "";
        if (
          !this.selectedItems.includes(item.id) &&
          !this.filter.excludeIds.value.includes(item.id)
        ) {
          this.filter.excludeIds.value.push(item.id);
        } else if (this.selectedItems.includes(item.id)) {
          this.filter.excludeIds.value = this.filter.excludeIds.value.filter(
            (i) => i !== item.id
          );
        }
      } else {
        this.allItemsSelected = false;
        this.indeterminate = false;
        this.filter.includeIds.value = [];
        this.filter.excludeIds.value = [];
        this.filter.allIds.value = "";
      }
    },
    applyShortFilter(data) {
      return data.map((item) => {
        return {
          id: item.id,
          idCounterpart: item.counterpart.id,
          name: item.counterpart.name,
          status: item.status.name,
          statusValue: item.status.value,
          started: item.started,
          currentSanctions: item.counterpart.has_sanctions,
          currenSanctionsDescription: item.counterpart.sanctions_description,
          newSanctions: item.changes
            ? item.changes.clarification.has_sanctions
            : null,
          newSanctionsDescription: item.changes
            ? item.changes.clarification.sanctions_description
            : null,
          deleted: item.deleted,
          _rowVariant: item.deleted ? "danger" : null,
        };
      });
    },
  },
  filters: {
    setStartTimeMoment: function (date) {
      return date
        ? moment(date).format("DD.MM.YYYY, HH:mm:ss")
        : "не почалась перевірка";
    },
  },
};
</script>

<style lang="scss">
#task-sanction-table td {
  word-wrap: break-word;
}
</style>
